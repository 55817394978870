import { useSelector } from "react-redux"
import PropTypes from "prop-types"

import Body from "../../../components/Body"
import { useNavigate } from "react-router-dom"
import { ROUTE_PATHS } from "../../../../data/configs/constants"

const ChatBody = ({scrollRef}) => {
  const navigate = useNavigate();
  const messages = useSelector((state) => state.botDetails.messages)

  return <Body scrollRef={scrollRef} messages={messages} handleBrainClicked={()=>{
    navigate(ROUTE_PATHS.KNOWLEDGE_SOURCE)
  }} />
}

ChatBody.propTypes = {
  messages: PropTypes.array,
}

ChatBody.defaultProps = {
  messages: [],
}

export default ChatBody
